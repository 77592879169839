import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import placeholder from '../images/placeholder.png'; // Ensure you have a placeholder image in the specified path

const TopArticles = ({  }) => {
  // Define your GraphQL query to fetch allPosts
  const data = useStaticQuery(graphql`
    query {
      allWordpressWpBlog (filter: { wordpress_id: { in: [23728, 23541, 23605] } }) {
        edges {
          node {
            wordpress_id
            slug
            title
            date(formatString: "MMMM DD, YYYY")
            modified(formatString: "MMMM DD, YYYY")
            acf {
              blog_grid_image {
                source_url
              }
              select_category
            }
          }
        }
      }
    }
  `);

  // Extract allPosts from the query result
  const allPosts = data. allWordpressWpBlog.edges;
  /*const topedge.nodesIDs =  ['19489', '19544', '19314'];
  console.log("topedge.nodesIDs->"+topedge.nodesIDs);
  const blogTopNews = allPosts
    .filter(edge => topedge.nodesIDs.includes(edge.node.wordpress_id))
    .map(edge => edge.node);
*/
  return (

 <>

        {allPosts.map((edge) => (
          <div key={edge.node.slug} className="col-sm-6 col-12 col-lg-4 program-box-wrapper margin-30px-bottom">
            <div className="blog-post-content pb-60 wow fadeInUp br-15 h-100 blog-text overflow-hidden pb-0 bg-white" data-wow-duration="1s" data-wow-delay=".1s">
              <Link to={`/study-resources/${edge.node.slug}/`} className="blog-image topnews border-0 programlink">
                <img src={edge.node.acf.blog_grid_image ? edge.node.acf.blog_grid_image.source_url : placeholder} className="img-fluid" alt={edge.node.title} />
              </Link>
              <div className="d-inline-block w-100">
                <div className="conline-icon-Triangle-ArrowUpt padding-1-half-rem-all lg-padding-1-half-rem-all xs-padding-20px-lr xs-padding-40px-top xs-padding-10px-bottom position-relative mx-auto">
                  <div className="blog-details-overlap text-small font-weight-500 yellowbg border-radius-4px alt-font text-white text-uppercase programlink">
                    {edge.node.acf.select_category.slice(0, 2).join(' • ')}
                  </div>
                  <h6 className="alt-font font-weight-500 margin-10px-top margin-15px-bottom text-extra-dark-gray">
                    <Link to={`/study-resources/${edge.node.slug}/`} className="text-extra-dark-gray programlink">
                      {edge.node.title.replace(/&#8211;/g, '-').replace(/&#8217;/g, '\'')}
                    </Link>
                  </h6>
                  <small className="text-muted">
  {`Updated on ${edge.node.modified ? edge.node.modified : edge.node.date}`}
</small>

                </div>
              </div>
            </div>
          </div>
        ))}
</>
  );
};

export default TopArticles;